<template>
  <div>
    <div
      v-if="broadcasts && broadcasts.length"
      class="broadcast-item d-flex direction-row align-center"
    >
      <div
        v-for="(item, index) in broadcasts"
        :key="index"
        class="d-flex direction-row"
      >
        <v-chip
          v-if="index < showItems"
          class="ma-1"
          label
          color="indigo"
          text-color="white"
          size="x-small"
        >
          {{ item.name }}
        </v-chip>
      </div>
      <span class="text-caption" v-if="broadcasts.length > 2 && !showAll"
        >(+{{ broadcasts.length - showCount }})</span
      >
    </div>
    <a
      v-if="broadcasts.length > 2"
      class="show-all"
      @click="showAll = !showAll"
      >{{ showAll ? "Hide" : "Show all" }}</a
    >
  </div>
</template>

<script>
export default {
  props: ["broadcasts"],
  data() {
    return {
      showCount: 2,
      showAll: false,
    };
  },
  computed: {
    showItems() {
      if (this.broadcasts.length > 2) {
        return this.showAll ? this.broadcasts.length : this.showCount;
      } else {
        return this.broadcasts.length;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.broadcast-item {
  max-width: 600px;
  width: 600px;
  flex-wrap: wrap;
  .v-chip.v-size--default {
    font-size: 11px !important;
    height: 25px !important;
  }
}
.show-all {
  text-decoration: none;
  font-size: 12px;
}
</style>
