import Http from "@/services/api.service";
import { service_url } from "@/services/config";

export function loadList(filter: string) {
    return Http._axios({
        url: `${service_url}/api/v1/broadcasts_groups/?${filter ? filter : ''}`,
        method: "get"
    });
}

export function createBroadcastGroup(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/broadcasts_groups/`,
        method: "post",
        data
    });
}

export function updateBroadcastGroup(id: any, data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/broadcasts_groups/${id}/`,
        method: "patch",
        data
    });
}