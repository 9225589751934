<template>
  <v-dialog :value="isOpen" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Broadcasts group</span>
      </v-card-title>
      <v-card-text>
        <v-container class="groups-wrp">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name"
                persistent-hint
                required
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="broadcast"
                :items="broadcassts_list"
                :loading="isLoadingBroadcasts"
                :search-input.sync="searchBroadcast"
                @focus="() => onLoadList()"
                item-text="name"
                label="Select broadcast"
                placeholder="Search"
                clearable
                return-object
                @change="setBroadcast(broadcast)"
                ref="selectedEl"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <div v-if="selectedBroadcasts && selectedBroadcasts.length">
                <!-- <div v-for="(item, index) in selectedBroadcasts" :key="index">
                  {{ item.name }}
                </div> -->
                <v-chip
                  v-for="(item, index) in selectedBroadcasts"
                  :key="index"
                  class="ma-1"
                  label
                  color="indigo"
                  text-color="white"
                  size="x-small"
                >
                  {{ item.name }}

                  <v-icon @click="removeBroadcast(index)"
                    >mdi-delete-forever</v-icon
                  >
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          :loading="is_loading"
          text
          @click="isOpen = false"
        >
          Close
        </v-btn>
        <v-btn @click="save" color="blue darken-1" :loading="is_loading" text>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { LOAD_LIST } from "../../../store/modules/contacts/types";
import {
  createBroadcastGroup,
  updateBroadcastGroup,
} from "@/store/modules/groups/api";
import { requestStatus } from "@/services/services";

export default {
  name: "GroupModal",
  props: ["calback"],
  data: () => ({
    selectedBroadcasts: [],
    isOpen: false,
    group: null,
    is_loading: false,
    broadcast: null,
    isLoadingBroadcasts: false,
    searchBroadcast: "",
    name: "",
  }),
  computed: {
    ...mapState({
      list: (state) => state.broadcasts.list,
    }),
    broadcassts_list() {
      return this.list && this.list.length ? this.list : [];
    },
  },
  mounted() {
    window.getApp.$on("OPEN_GROUP_DIALOG", (item) => {
      if (item.name) {
        this.group = JSON.parse(JSON.stringify(item));
        this.selectedBroadcasts = this.group.broadcasts;
        this.name = this.group.name;
      } else {
        this.selectedBroadcasts = [];
      }
      this.isOpen = true;
    });
  },
  watch: {
    isOpen() {
      if (!this.isOpen) {
        this.selectedBroadcasts = [];
        this.group = null;
        this.name = "";
      }
    },
    searchBroadcast() {
      this.onWaitChangeCountry();
    },
  },
  methods: {
    ...mapActions({
      onLoadList: `broadcasts/${LOAD_LIST}`,
    }),
    save() {
      if (this.name && this.selectedBroadcasts.length) {
        this.is_loading = true;

        let data = {
          name: this.name,
          broadcasts: this.selectedBroadcasts.map((item) => item.id),
        };
        if (this.group) {
          updateBroadcastGroup(this.group.id, data)
            .then((response) => {
              this.responseCalback(response);
            })
            .finally(() => {
              this.is_loading = false;
            });
        } else {
          createBroadcastGroup(data)
            .then((response) => {
              this.responseCalback(response);
            })
            .finally(() => {
              this.is_loading = false;
            });
        }
      } else {
        this.$toaster.error("Set name and broadcasts");
      }
    },
    responseCalback(response) {
      if (response.status === requestStatus.success) {
        this.$toaster.success("Saved");
        this.isOpen = false;
        this.calback();
      } else {
        this.$toaster.error(
          response.messages ||
            "Oops, something went wrong. Messages were not loaded."
        );
      }
    },
    setBroadcast(broadcast) {
      if (
        broadcast &&
        !this.selectedBroadcasts.find((item) => broadcast.id == item.id)
      ) {
        this.selectedBroadcasts.push(broadcast);
        this.broadcast = null;
        this.searchBroadcast = "";
      }
      this.$refs["selectedEl"].reset();
    },
    removeBroadcast(index) {
      this.selectedBroadcasts.splice(index, 1);
    },
    onWaitChangeCountry() {
      if (
        this.searchBroadcast &&
        (this.searchBroadcast.length >= 2 || this.searchBroadcast === "")
      ) {
        this.onLoadList(`search=${this.searchBroadcast}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-wrp {
  .v-chip.v-size--default {
    font-size: 11px !important;
    height: 25px !important;
    .v-icon {
      font-size: 16px;
      margin-left: 4px;
      cursor: pointer;
    }
  }
}
</style>
