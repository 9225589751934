<template>
  <div>
    <v-divider></v-divider>
    <div class="pa-4">
      <v-container>
        <v-row
            align="center"
            justify="space-between"
        >

          <v-col
              cols="12"
              sm="6"
          >
            <span class="grey--text">Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    text
                    color="primary"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  {{ limit }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col
              cols="12"
              sm="auto"
          >       <span
              class="mr-4
            grey--text"
          >
            Page {{ currentPage }} of {{ numberOfPages }}
          </span>
            <v-btn
                fab
                color="primary"
                class="mr-1"
                @click="formerPage"
                small
                :disabled="!isActivePrevPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
                fab
                color="primary"
                class="ml-1"
                @click="nextPage"
                small
                :disabled="!isActiveNextPage"
            >
              <v-icon dark>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>

        </v-row>
      </v-container>
    </div>

  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import {CHANGE_FILTER} from "../store/modules/page/types";

const calculatePagesCount = (data, elements) => {
  return Math.ceil(+data / +elements);
};

export default {
  name: "Pagination",
  props: {
    count: Number
  },
  data: () => ({
    itemsPerPageArray: [25, 50, 100],
    currentPage: 1
  }),
  computed: {
    ...mapState({
      limit: state => (state.page.filter ? +state.page.filter.limit : 0),
      offset: state => (state.page.filter ? +state.page.filter.offset : 0)
    }),
    numberOfPages() {
      return Math.ceil(this.count / this.limit)
    },
    isActiveNextPage(){
      return this.currentPage + 1 <= this.numberOfPages;
    },
    isActivePrevPage(){
      return this.currentPage - 1 >= 1;
    }
  },
  methods: {
    ...mapMutations({
      changeFilter: `page/${CHANGE_FILTER}`,
    }),
    changePage: function(newValue) {
      if (newValue !== calculatePagesCount(this.offset, this.limit) + 1) {
        this.changeFilter({ offset: (newValue - 1) * this.limit });
      }
    },
    nextPage() {
      if (this.isActiveNextPage) this.changePage(this.currentPage + 1)
    },
    formerPage() {
      if (this.isActivePrevPage) this.changePage(this.currentPage - 1)
    },
    updateItemsPerPage(number) {
      this.changeFilter({ limit: number });
    },
  },
  watch: {
    currentPage(newValue) {
      if (newValue !== calculatePagesCount(this.offset, this.limit) + 1) {
        this.changeFilter({offset: (newValue - 1) * this.limit});
      }
    },
    offset(newValue) {
      let currentPage = calculatePagesCount(newValue, this.limit) + 1;
      if (currentPage !== this.currentPage) {
        this.currentPage = currentPage;
      }
    }
  },
  mounted: function () {
    this.currentPage = calculatePagesCount(this.offset, this.limit) + 1;
  },
  created: function () {
    this.currentPage = calculatePagesCount(this.offset, this.limit) + 1;
  }
}
</script>

<style scoped>

</style>