<template>
  <v-card>
    <v-card-title class="d-flex justify-content-between">
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="searchName"
            append-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn @click="openGroupDialog" color="primary" class="ma-2">
        Create Group
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="data"
        hide-default-footer
        :loading="loading"
        :itemsPerPage="+pageFilter.limit"
        disable-sort
      >
        <template v-slot:item.actions="{ item }">
          <div>
            <v-btn
              @click="openGroupDialog(item)"
              icon
              color="success"
              :title="`Edit`"
              class="mr-1"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:item.user="{ item }">
          <div>{{ item.creator.username }}</div>
        </template>
        <template v-slot:item.broadcasts="{ item }">
          <broadcasts-list :broadcasts="item.broadcasts" />
        </template>

        <template v-slot:footer>
          <pagination :count="count" />
        </template>
      </v-data-table>
    </v-card-text>
    <group-dialog :calback="onLoadData" />
  </v-card>
</template>

<script>
import Pagination from "@/components/Pagination";
import GroupDialog from "./GroupDialog.vue";
import { mapState } from "vuex";
import { loadList } from "@/store/modules/groups/api";
import { requestStatus, buildFilterString } from "@/services/services";
import broadcastsList from "./BroadcastsList.vue";
export default {
  name: "GroupsTable",
  components: { Pagination, GroupDialog, broadcastsList },
  props: { toggleOpenDetailsModal: Function },
  data: () => ({
    searchName: "",
    count: 0,
    data: [],
    headers: [
      {
        text: "Name",
        value: "name",
      },
      { text: "User", value: "user" },
      { text: "Broadcasts", value: "broadcasts" },
      { text: "", value: "actions" },
    ],
    loading: false,
  }),
  computed: {
    ...mapState({
      pageFilter: (state) => state.page.filter,
    }),
  },
  watch: {
    pageFilter(newValue) {
      this.onLoadData(newValue);
    },
    searchName(searchName) {
      if (searchName.length >= 2 || searchName === "") {
        this.onLoadData(this.pageFilter);
      }
    },
  },
  methods: {
    openGroupDialog(item) {
      window.getApp.$emit("OPEN_GROUP_DIALOG", item ? item : null);
    },
    onLoadData(pageFilter) {
      this.loading = true;
      loadList(
        `${buildFilterString(pageFilter)}&ordering=-id&search=${
          this.searchName ? this.searchName : ""
        }`
      )
        .then((response) => {
          if (response.status === requestStatus.success) {
            // console.log(response.response.data);
            this.count = response.response.data.count;
            this.data = response.response.data.results;
          } else {
            this.$toaster.error(
              response.messages ||
                "Oops, something went wrong. Messages were not loaded."
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.onLoadData(this.pageFilter);
  },
};
</script>
