<template>
  <div>
    <Table />
  </div>
</template>

<script>
import Table from "./components/Table.vue";
export default {
  components: { Table },
};
</script>
